import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { EntityContext } from "../../App";

const SwitchEntityDialog = (props) => {
  const { visible, setVisible } = props;

  const { entityList, setActiveEntity } = useContext(EntityContext);

  const handleClose = () => setVisible(false);

  return (
    <Dialog open={visible} onClose={handleClose} fullWidth>
      <DialogTitle>Switch Entity</DialogTitle>
      <DialogContent>
        <List>
          {entityList.map((item) => (
            <ListItem disablePadding key={item._id}>
              <ListItemButton
                onClick={() => {
                  setActiveEntity(item);
                  handleClose();
                }}
              >
                <ListItemText primary={item["Entity/Name"]} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SwitchEntityDialog;
