// material-ui
import { Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import LocalDrinkOutlinedIcon from "@mui/icons-material/LocalDrinkOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import OpacityIcon from "@mui/icons-material/Opacity";
import LabelIcon from "@mui/icons-material/Label";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import InsightsIcon from "@mui/icons-material/Insights";
import InventoryIcon from "@mui/icons-material/Inventory";

// project imports
import NavGroup from "./NavGroup";
import { EntityContext } from "../../../App";
import { useContext } from "react";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const menuItems = {
  items: [
    {
      id: "sinisana",
      title: "Sinisana",
      type: "group",
      permission: "Sinisana",
      children: [
        {
          id: "security",
          title: "Intersect Security",
          type: "item",
          url: "/admin/sinisana/security",
        },
        {
          id: "entity",
          title: "Entity",
          type: "item",
          url: "/admin/sinisana/entities",
        },
        {
          id: "user",
          title: "User",
          type: "item",
          url: "/admin/sinisana/users",
        },
        {
          id: "analytics",
          title: "Analytics",
          type: "item",
          url: "/admin/dashboard/analytics",
          icon: InsightsIcon,
        },
      ],
    },
    {
      id: "dashboard",
      title: "Dashboard",
      type: "group",
      permission: "Dashboard",
      children: [
        {
          id: "main",
          title: "Dashboard",
          type: "item",
          url: "/admin/dashboard",
          icon: EqualizerIcon,
        },
        {
          id: "monitor",
          title: "Monitor",
          type: "item",
          url: "/admin/dashboard/monitor",
          icon: DesktopWindowsOutlinedIcon,
        },
      ],
    },
    {
      id: "process",
      title: "Processes",
      type: "group",
      permission: "Process",
      children: [
        {
          id: "distillation",
          title: "EO Distillation",
          type: "item",
          url: "/admin/essentialOilDistillations",
          icon: LocalDrinkOutlinedIcon,
        },
        {
          id: "verification",
          title: "EO Verification",
          type: "item",
          url: "/admin/essentialOilVerification",
          icon: PublishedWithChangesOutlinedIcon,
        },
      ],
    },
    {
      id: "transfers",
      title: "Transfers",
      type: "group",
      permission: "StockTransfer.View",
      children: [
        {
          id: "stockTransfer",
          title: "Stock Transfer",
          type: "item",
          url: "/admin/stockTransfers",
          icon: LocalShippingIcon,
        },
      ],
    },
    {
      id: "reports",
      title: "Reports",
      type: "group",
      permission: "Report.View",
      children: [
        {
          id: "distillationRecord",
          title: "Distillation Record",
          type: "item",
          url: "/admin/distillationRecord",
          icon: AssignmentIcon,
        },
        {
          id: "plantMaterial&EssentialOil",
          title: "Plant Material & Essential Oil",
          type: "item",
          url: "/admin/plantMaterialEssentialOil",
          icon: AssignmentIcon,
        },
      ],
    },
    {
      id: "products",
      title: "Products",
      type: "group",
      permission: "Product",
      children: [
        {
          id: "essentialOil",
          title: "Essential Oil",
          type: "item",
          url: "/admin/essentialOils",
          icon: OpacityIcon,
        },
        {
          id: "litsaraProduct",
          title: "Litsara Product",
          type: "item",
          url: "/admin/litsaraProducts",
          icon: LabelIcon,
        },
      ],
    },
    {
      id: "transactions",
      title: "Transactions",
      type: "group",
      permission: "Transaction",
      children: [
        {
          id: "payments",
          title: "Payments",
          type: "item",
          url: "/admin/payments",
          icon: AccountBalanceWalletOutlinedIcon,
        },
      ],
    },
    {
      id: "settings",
      title: "Settings",
      type: "group",
      permission: "Settings",
      children: [
        {
          id: "communities",
          title: "Community",
          type: "item",
          url: "/admin/settings/communities",
          icon: GroupsOutlinedIcon,
        },
        {
          id: "skus",
          title: "SKU List",
          type: "item",
          url: "/admin/settings/skus",
          icon: InventoryIcon,
        },
      ],
    },
  ],
};

const MenuList = () => {
  const { can } = useContext(EntityContext);

  const navItems = menuItems.items
    .filter((item) => {
      if (!item.permission) return true;
      return can(item.permission);
    })
    .map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return <>{navItems}</>;
};

export default MenuList;
