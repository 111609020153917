import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEntityService } from "../entity/useEntityService";
import { useForm, Controller } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EntityTypeWithRole } from "../../../constants/Entity";
import { useUserService } from "./useUserService";

const schema = object().shape({
  entityId: string().required("Entity is required."),
  role: string().required("Role is required."),
});

const NewUserEntityDialog = (props) => {
  const { id, visible, setVisible, setRefresh } = props;

  const { findAll } = useEntityService();
  const { addEntity } = useUserService();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [entityOption, setEntityOption] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedEntityType, setSelectedEntityType] = useState(null);

  useEffect(() => {
    if (visible) {
      findAll().then((res) => {
        if (res.status === "success") {
          setEntityOption(res.data);
        }
      });
    }
  }, [visible]);

  useEffect(() => {
    if (selectedEntity) {
      const index = entityOption.findIndex((item) => item._id === selectedEntity);
      if (index !== -1) {
        setSelectedEntityType(entityOption[index]["Entity/Types"]);
      }
    } else {
      setSelectedEntityType(null);
    }
  }, [selectedEntity]);

  const submit = (formData) => {
    const params = {
      entityId: formData.entityId,
      role: formData.role,
    };

    addEntity(id, params).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        reset();
        handleClose();
      }
    });
  };

  const handleClose = () => setVisible(false);

  return (
    <Dialog open={visible} onClose={handleClose} fullWidth>
      <DialogTitle>New Entity & Role</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="entityId"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <FormControl fullWidth style={{ marginTop: 16 }} error={!!errors.entityId}>
                  <InputLabel>Entity</InputLabel>
                  <Select
                    id="entityId"
                    value={value}
                    label="Entity"
                    onChange={(e) => {
                      setSelectedEntity(e.target.value);
                      onChange(e.target.value);
                    }}
                  >
                    {entityOption.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item["Entity/Name"]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.entityId?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="role"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <FormControl fullWidth style={{ marginTop: 16 }} error={!!errors.role}>
                  <InputLabel>Role</InputLabel>
                  <Select id="role" value={value} label="Role" onChange={(e) => onChange(e.target.value)}>
                    {EntityTypeWithRole[selectedEntityType] &&
                      EntityTypeWithRole[selectedEntityType].map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors.role?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit((data) => submit(data))}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUserEntityDialog;
