import { httpFetch } from "./SecurityApiService";
import { fp } from "../App";

const checkAuth = async () => {
  const config = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      FpId: (await fp).visitorId,
    },
    credentials: "include",
  };
  return await httpFetch("/auth", config);
};

const login = async (params) => {
  const config = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      FpId: (await fp).visitorId,
    },
    body: JSON.stringify(params),
    credentials: "include",
  };

  return await httpFetch("/login", config);
};

const logout = async () => {
  const config = {
    method: "post",
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("authToken"),
      "Content-Type": "application/json",
      FpId: (await fp).visitorId,
    },
    credentials: "include",
  };
  return await httpFetch("/logout", config);
};

export default {
  checkAuth,
  login,
  logout,
};
