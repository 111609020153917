import React, { useState, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// material-ui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

import { AuthContext, EntityContext } from "../../App";
import config from "../../config";
import Sidebar from "./Sidebar";
import Header from "./Header";
import PageLoader from "../loadable/PageLoader";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(config.drawerWidth - 20),
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: "100%",
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
      padding: "16px",
      marginRight: "10px",
    },
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${config.drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  }),
}));

export const AdminLayoutContext = React.createContext();

export default function AdminLayout() {
  const { authState } = useContext(AuthContext);
  const { loading } = useContext(EntityContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    // when accessing admin routes, check if user is authenticated. If not, redirect to login page
    // if (!loading && !authState.isAuthenticated) {
    //   console.log("hahaha");
    //   navigate("/", { replace: true });
    // }
    console.log("loading", loading);
  }, [loading, authState]);
  // useEffect(() => {
  //   if (!authState.isAuthenticated) {
  //     AuthService.checkAuth().then((res) => {
  //       if (res.status === "success") {
  //         dispatch({
  //           type: "LOGIN",
  //           payload: {
  //             authUser: res.data.auth,
  //             token: res.data.token,
  //           },
  //         });
  //       } else {
  //         dispatch({ type: "LOGOUT" });
  //       }
  //       setLoading(false);
  //     });
  //   } else {
  //     setLoading(false);
  //   }
  // }, [authState]);

  useEffect(() => {
    setSidebarOpen(!matchDownMd);
  }, [matchDownMd]);

  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <AdminLayoutContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            transition: sidebarOpen ? theme.transitions.create("width") : "none",
          }}
        >
          <Toolbar>
            <Header handleDrawerToggle={handleDrawerToggle} />
          </Toolbar>
        </AppBar>

        <Sidebar drawerOpen={sidebarOpen} drawerToggle={handleDrawerToggle} />
        {loading ? (
          <PageLoader />
        ) : (
          <Main
            theme={theme}
            open={sidebarOpen}
            style={{ backgroundColor: "#f9f9f9", height: "100%", padding: 0, marginTop: "64px" }}
          >
            <Outlet />
          </Main>
        )}
      </Box>
    </AdminLayoutContext.Provider>
  );
}
