import { lazy, useContext } from "react";
import { useRoutes } from "react-router-dom";
import config from "../config";
import AdminRoutes from "./AdminRoutes";
import Loadable from "../components/loadable/Loadable";
import { EntityContext } from "../App";
const Login = Loadable(lazy(() => import("../views/login/Login")));
const DeleteAccount = Loadable(lazy(() => import("../modules/deleteAccount/DeleteAccount")));

export default function ThemeRoutes() {
  const { can } = useContext(EntityContext);

  return useRoutes(
    [
      AdminRoutes(can),
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/deleteAccount",
        element: <DeleteAccount />,
      },
    ],
    config.basename
  );
}
