import { lazy } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// project imports
import AdminLayout from "../components/layouts/AdminLayout";
import Loadable from "../components/loadable/Loadable";
import UserDetail from "../modules/sinisana/user/UserDetail";

const Profile = Loadable(lazy(() => import("../views/profile/profile")));

// Dashboard
const Dashboard = Loadable(lazy(() => import("../modules/dashboard/Dashboard")));
const Monitor = Loadable(lazy(() => import("../modules/dashboard/Monitor")));
const Analytics = Loadable(lazy(() => import("../modules/dashboard/Analytics")));

// Process
const Process = Loadable(lazy(() => import("../modules/essentialOilDistillation/Process")));
const NewDistillationProcess = Loadable(
  lazy(() => import("../modules/essentialOilDistillation/NewDistillationProcess"))
);
const StockVerification = Loadable(lazy(() => import("../modules/essentialOilVerification/StockVerification")));

// Stock Transfer
const StockTransfer = Loadable(lazy(() => import("../modules/stockTransfer/StockTransfer")));
const StockTransferDetail = Loadable(lazy(() => import("../modules/stockTransfer/StockTransferDetail")));

// Reports
const DistillationRecord = Loadable(lazy(() => import("../modules/report/DistillationRecord")));
const PlantMaterialEssentialOil = Loadable(lazy(() => import("../modules/report/PlantMaterialEssentialOil")));

// Products
const EssentialOil = Loadable(lazy(() => import("../modules/product/essentialOil/EssentialOil")));
const NewEssentialOil = Loadable(lazy(() => import("../modules/product/essentialOil/NewEssentialOil")));
const EssentialOilDetail = Loadable(lazy(() => import("../modules/product/essentialOil/EssentialOilDetail")));
const LitsaraProduct = Loadable(lazy(() => import("../modules/product/litsara/LitsaraProduct")));
const NewLitsaraProduct = Loadable(lazy(() => import("../modules/product/litsara/NewLitsaraProduct")));
const LitsaraProductDetail = Loadable(lazy(() => import("../modules/product/litsara/LitsaraProductDetail")));

// Transactions
const Payment = Loadable(lazy(() => import("../modules/payment/Payment")));
const NewPayment = Loadable(lazy(() => import("../modules/payment/NewPayment")));
const PaymentDetail = Loadable(lazy(() => import("../modules/payment/PaymentDetail")));

// Settings
const Community = Loadable(lazy(() => import("../modules/community/Community")));
const CommunityDetail = Loadable(lazy(() => import("../views/settings/community/CommunityDetail")));
const NewCommunityStory = Loadable(lazy(() => import("../views/settings/community/NewCommunityStory")));
const EditCommunityStory = Loadable(lazy(() => import("../views/settings/community/EditCommunityStory")));
const SKU = Loadable(lazy(() => import("../modules/sku/SKU")));

// Sinisana
const Security = Loadable(lazy(() => import("../views/sinisana/security/Security")));
const SecurityDetail = Loadable(lazy(() => import("../views/sinisana/security/SecurityDetail")));
const Entity = Loadable(lazy(() => import("../modules/sinisana/entity/Entity")));
const EntityDetail = Loadable(lazy(() => import("../modules/sinisana/entity/EntityDetail")));
const User = Loadable(lazy(() => import("../modules/sinisana/user/User")));

const PermissionDenied = () => {
  return (
    <Grid container padding={{ xs: 0, md: 2 }} marginTop={{ md: "24px" }} spacing={2}>
      <Grid item xs={12}>
        <Box>
          <h1>Permission Denied</h1>
          <p>You don't have permission to access this page. Please contact your administrator.</p>
        </Box>
      </Grid>
    </Grid>
  );
};

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = (can) => {
  return {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "dashboard",
        element: can("Dashboard.View") ? <Dashboard /> : <PermissionDenied />,
      },
      {
        path: "dashboard/monitor",
        element: can("Monitor") ? <Monitor /> : <PermissionDenied />,
      },
      {
        path: "essentialOilDistillations",
        element: can("Distillation.View") ? <Process /> : <PermissionDenied />,
      },
      {
        path: "essentialOilDistillations/new",
        element: can("Distillation.Create") ? <NewDistillationProcess /> : <PermissionDenied />,
      },
      {
        path: "dashboard/analytics",
        // element: can("Analytics.View") ? <Analytics /> : <PermissionDenied />,
        element: can("Sinisana") ? <Analytics /> : <PermissionDenied />,
      },
      {
        path: "essentialOils",
        element: can("EssentialOil.View") ? <EssentialOil /> : <PermissionDenied />,
      },
      {
        path: "essentialOils/new",
        element: can("EssentialOil.Create") ? <NewEssentialOil /> : <PermissionDenied />,
      },
      {
        path: "essentialOils/:id",
        element: can("EssentialOil.View") ? <EssentialOilDetail /> : <PermissionDenied />,
      },
      {
        path: "litsaraProducts",
        element: can("LitsaraProduct") ? <LitsaraProduct /> : <PermissionDenied />,
      },
      {
        path: "litsaraProducts/new",
        element: can("LitsaraProduct") ? <NewLitsaraProduct /> : <PermissionDenied />,
      },
      {
        path: "litsaraProducts/:id",
        element: can("LitsaraProduct") ? <LitsaraProductDetail /> : <PermissionDenied />,
      },
      {
        path: "stockTransfers",
        element: can("StockTransfer.View") ? <StockTransfer /> : <PermissionDenied />,
      },
      {
        path: "stockTransfers/:id",
        element: can("StockTransfer.View") ? <StockTransferDetail /> : <PermissionDenied />,
      },
      {
        path: "distillationRecord",
        element: can("Report.View") ? <DistillationRecord /> : <PermissionDenied />,
      },
      {
        path: "plantMaterialEssentialOil",
        element: can("Report.View") ? <PlantMaterialEssentialOil /> : <PermissionDenied />,
      },
      {
        path: "essentialOilVerification",
        element: can("Verification.View") ? <StockVerification /> : <PermissionDenied />,
      },
      {
        path: "payments",
        element: can("Payment") ? <Payment /> : <PermissionDenied />,
      },
      {
        path: "payments/new",
        element: can("Payment.Create") ? <NewPayment /> : <PermissionDenied />,
      },
      {
        path: "payments/:id",
        element: can("Payment") ? <PaymentDetail /> : <PermissionDenied />,
      },
      {
        path: "settings/communities",
        element: can("Settings") ? <Community /> : <PermissionDenied />,
      },
      {
        path: "settings/communities/:id",
        element: can("Settings") ? <CommunityDetail /> : <PermissionDenied />,
      },
      {
        path: "settings/communities/:id/stories/new",
        element: can("Settings") ? <NewCommunityStory /> : <PermissionDenied />,
      },
      {
        path: "settings/communities/:id/stories/:storyId",
        element: can("Settings") ? <EditCommunityStory /> : <PermissionDenied />,
      },
      {
        path: "settings/skus",
        element: can("Settings") ? <SKU /> : <PermissionDenied />,
      },
      {
        path: "sinisana/security",
        element: can("Sinisana") ? <Security /> : <PermissionDenied />,
      },
      {
        path: "sinisana/security/:id",
        element: can("Sinisana") ? <SecurityDetail /> : <PermissionDenied />,
      },
      {
        path: "sinisana/entities",
        element: can("Sinisana") ? <Entity /> : <PermissionDenied />,
      },
      {
        path: "sinisana/entities/:id",
        element: can("Sinisana") ? <EntityDetail /> : <PermissionDenied />,
      },
      {
        path: "sinisana/users",
        element: can("Sinisana") ? <User /> : <PermissionDenied />,
      },
      {
        path: "sinisana/users/:id",
        element: can("Sinisana") ? <UserDetail /> : <PermissionDenied />,
      },
    ],
  };
};

export default AdminRoutes;
