import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useUserService } from "./useUserService";

const DeleteUserEntityDialog = (props) => {
  const { id, deleteItem, visible, setVisible, setRefresh } = props;

  const { removeEntity } = useUserService();

  const submit = () => {
    removeEntity(id, {
      role: deleteItem,
    }).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        handleClose();
      }
    });
  };

  const handleClose = () => setVisible(false);

  return (
    <Dialog open={visible} onClose={handleClose} fullWidth>
      <DialogTitle>Remove Entity & Role</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure to remove current user from this entity?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={submit} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserEntityDialog;
