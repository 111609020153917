import { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link as RouterLink } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  Typography,
  Paper,
  Popper,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ClickAwayListener,
  Fade,
  Link,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AuthService from "../../services/AuthService";
import { AuthContext, EntityContext } from "../../App";
import IconButton from "@mui/material/IconButton";
import SwitchEntityDialog from "../switchEntity/SwitchEntityDialog";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const { activeEntity, accountEntity } = useContext(EntityContext);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [settingsMenu, setSettingsMenu] = useState(false);

  const [switchEntityDialog, setSwitchEntityDialog] = useState(false);

  const anchorRef = useRef(null);

  const handleLogout = () => {
    AuthService.logout();
    dispatch({ type: "LOGOUT" });
    return navigate("/", { replace: true });
  };

  //   const handleListItemClick = (event, index, route = "") => {
  //     setSelectedIndex(index);
  //     handleClose(event);
  //     if (route && route !== "") {
  //       navigate(route);
  //     }
  //   };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setSettingsMenu(false);
  };

  const handleToggle = () => {
    setSettingsMenu((prevOpen) => !prevOpen);
  };

  return (
    <>
      <>
        <IconButton
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ display: { xs: "none", sm: "block" } }}>
          Access Benefit Sharing
        </Typography>

        <Typography variant="h6" marginLeft={4}>
          <Button
            variant="text"
            onClick={() => {
              setSwitchEntityDialog(true);
            }}
          >
            {activeEntity ? activeEntity["Entity/Name"] : null}
          </Button>
        </Typography>

        <Box sx={{ flexGrow: 1 }} />
        <Box marginRight={4}>
          <Link component={RouterLink} to="/admin/profile">
            {accountEntity ? accountEntity["Entity/Name"] : null}
          </Link>
        </Box>
        <Chip
          sx={{
            height: "48px",
            alignItems: "center",
            borderRadius: "27px",
            transition: "all .2s ease-in-out",
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light,
              "& svg": {
                stroke: theme.palette.primary.light,
              },
            },
            "& .MuiChip-label": {
              lineHeight: 0,
            },
          }}
          label={<SettingsIcon stroke={1.5} size="1.5rem" />}
          ref={anchorRef}
          aria-controls={settingsMenu ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
        <Popper
          placement="bottom-end"
          open={settingsMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box sx={{ px: 1.5 }}>
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        //   maxWidth: 350,
                        //   minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
                        <ListItemIcon>
                          <LogoutIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                      </ListItemButton>
                    </List>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </>
      <SwitchEntityDialog visible={switchEntityDialog} setVisible={setSwitchEntityDialog} />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
