import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useUserService } from "./useUserService";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NewUserEntityDialog from "./NewUserEntityDialog";
import DeleteUserEntityDialog from "./DeleteUserEntityDialog";

const UserDetail = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { findOne } = useUserService();

  const [refresh, setRefresh] = useState(true);

  const [data, setData] = useState({});
  const [entityData, setEntityData] = useState([]);

  const [editItem, setEditItem] = useState({});
  const [newUserEntityDialog, setNewUserEntityDialog] = useState(false);
  const [deleteUserEntityDialog, setDeleteUserEntityDialog] = useState(false);

  useEffect(() => {
    if (id && refresh) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setData(res.data);
          setEntityData(res.data["Entity/_Manages"] ? res.data["Entity/_Manages"] : []);
        }
        setRefresh(false);
      });
    }
  }, [id, refresh, findOne]);

  const deleteRow = (item) => {
    setEditItem(item);
    setDeleteUserEntityDialog(true);
  };

  return (
    <Grid container padding={{ xs: 0, md: 2 }} marginTop={{ md: "24px" }} rowSpacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ cursor: "pointer" }} onClick={() => navigate("/admin/sinisana/users")}>
            User
          </Link>
          <Typography color="text.primary">{id}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Box p={2}>
            <Typography variant="h4">{data["Entity/Name"]}</Typography>
          </Box>

          <Divider />

          <Stack direction="row">
            <ListItem>
              <ListItemText primary="Name" secondary={data["Entity/Name"]} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Types" secondary={data["Entity/Types"]} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1">Security</Typography>
                    <Tooltip
                      title={
                        "This field must match with Intersect Security username. It links the auth record in Intersect Security with the user entity in this app."
                      }
                    >
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </Stack>
                }
                secondary={data["Entity/SecurityUsers"] ? data["Entity/SecurityUsers"].join(", ") : []}
              />
            </ListItem>
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={0}>
          <Stack p={2} direction="row" justifyContent={"space-between"}>
            <Typography variant="h6">Entity & Roles</Typography>
            <IconButton color="primary" onClick={() => setNewUserEntityDialog(true)}>
              <AddIcon />
            </IconButton>
          </Stack>
          <Divider />

          <List>
            {refresh ? (
              <ListItem>
                <ListItemText primary={"Loading..."} />
              </ListItem>
            ) : !data["Entity/Roles"] || data["Entity/Roles"].length <= 0 ? (
              <ListItem>
                <ListItemText primary={"No data found"} />
              </ListItem>
            ) : (
              data["Entity/Roles"]
                .map((item) => JSON.parse(item))
                .map((item) => (
                  <ListItem
                    key={item["EntityId"] + item["role"]}
                    secondaryAction={
                      <Stack direction="row">
                        <IconButton onClick={() => deleteRow(item)}>
                          <DeleteIcon color="warning" />
                        </IconButton>
                      </Stack>
                    }
                  >
                    <ListItemText
                      primary={
                        entityData.find((entity) => entity["_id"] == item["EntityId"])
                          ? entityData.find((entity) => entity["_id"] == item["EntityId"])["Entity/Name"]
                          : item["EntityId"]
                      }
                      secondary={item["Role"]}
                    />
                  </ListItem>
                ))
            )}
          </List>
        </Paper>
      </Grid>

      <NewUserEntityDialog
        id={id}
        visible={newUserEntityDialog}
        setVisible={setNewUserEntityDialog}
        setRefresh={setRefresh}
      />

      <DeleteUserEntityDialog
        id={id}
        deleteItem={editItem}
        visible={deleteUserEntityDialog}
        setVisible={setDeleteUserEntityDialog}
        setRefresh={setRefresh}
      />

      {/* <NewAccountIntersectDialog
        account={data}
        visible={newAccountIntersectDialog}
        setVisible={setNewAccountIntersectDialog}
        setRefresh={setRefresh}
      /> */}
    </Grid>
  );
};

export default UserDetail;
